.TextArea {
  max-width: 900px;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }
  .TextArea textarea {
    width: 100%;
    max-width: 900px;
    height: 100%;
    background-color: #d0e9fb;
    border: none;
    border-radius: 4px;
    resize: none;
    font-family: 'Roboto Mono', monospace;
    font-size: 13px;
    line-height: 1.8; }
