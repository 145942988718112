.FileReader label {
  width: 100%;
  color: #ffc107;
  text-align: center;
  transition: .25s; }

.FileReader label:hover {
  color: #ffffff; }

.FileReader input[type="file"] {
  display: none; }
