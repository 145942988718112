.DocumentButton button.dropdown-toggle {
  width: 225px; }

.DocumentButton .dropdown-menu {
  margin-top: 16px;
  background-color: #212121;
  border-color: #212121;
  border-radius: 0px 0px 5px 5px;
  right: 0px; }

.DocumentButton .dropdown-item {
  margin: auto; }

.DocumentButton button.dropdown-item {
  margin-bottom: 16px;
  width: 225px;
  padding: 0px;
  background-color: #212121;
  text-align: center;
  color: #ffc107;
  transition: 0.25s;
  display: flex;
  justify-content: center; }

.DocumentButton button.dropdown-item:focus {
  outline: none; }

.DocumentButton button.dropdown-item:hover {
  color: #ffffff; }

.DocumentButton .dropdown-divider {
  margin: 16px 20px;
  border-top: 1px solid white;
  opacity: 0.1; }

.DocumentButton label {
  margin-bottom: 16px; }
