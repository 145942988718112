body {
  background-color: #1566b7 !important;
  text-align: center; }

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  box-shadow: none !important; }

* {
  letter-spacing: 0px !important; }
