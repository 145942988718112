.FileNameInput {
  text-align: center; }
  .FileNameInput input {
    background-color: #212121;
    color: #ffc107;
    border: none;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.25s; }
  .FileNameInput input:focus {
    outline: none;
    color: #ffffff;
    cursor: text; }
  .FileNameInput input:hover {
    color: #ffffff;
    cursor: text; }
