.MarkedText {
  max-width: 900px;
  min-height: 100%;
  background-color: #d0e9fb;
  text-align: left;
  border-radius: 4px;
  overflow-x: auto;
  cursor: default;
  line-height: 1.8;
  margin: auto; }
  .MarkedText img {
    max-width: 100%;
    object-fit: contain; }
  .MarkedText table {
    margin-bottom: 16px; }
    .MarkedText table td,
    .MarkedText table tr,
    .MarkedText table th {
      padding: 8px;
      border: 1px solid white; }
  .MarkedText pre {
    padding: 8px;
    border: 1px solid white;
    border-radius: 5px;
    cursor: text;
    line-height: 1.8; }
  .MarkedText h2 {
    margin-bottom: 16px;
    margin-top: 24px; }
  .MarkedText h3 {
    margin-bottom: 16px;
    margin-top: 24px; }
  .MarkedText a {
    color: #1467b8; }
  .MarkedText blockquote {
    padding: 4px 16px;
    font-style: italic; }
