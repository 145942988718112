.HeaderView {
  background-color: #212121;
  opacity: 0.98; }
  .HeaderView .navbar {
    width: 100%; }
  .HeaderView a.navbar-brand {
    color: #ffc107;
    transition: 0.25s;
    letter-spacing: 1px; }
  .HeaderView .nav-item {
    margin-top: auto;
    margin-bottom: auto; }
    .HeaderView .nav-item .btn-group {
      display: flex;
      width: 100%; }
  .HeaderView .nav-link {
    padding: 0px;
    margin-right: auto; }
    .HeaderView .nav-link span {
      color: #ffc107;
      transition: 0.25s; }
    .HeaderView .nav-link span:hover {
      color: #fff; }
  .HeaderView label {
    min-width: 110px;
    margin-bottom: 0px;
    cursor: pointer;
    letter-spacing: 1px; }
  .HeaderView button {
    cursor: pointer;
    letter-spacing: 1px; }
